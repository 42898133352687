<template>
  <section class="section">
    <div class="container">
      <amplify-connect :query="listServicesQuery">
        <template slot-scope="{loading, data, errors}">
          <div v-if="loading">Loading...</div>

          <div v-else-if="errors.length > 0">Error occured</div>

          <div v-else-if="data">
            <div v-for="service in data.listServices.items" v-bind:key="service.id">
              <ul>
                <li><b>{{ service.title }}</b></li>
                <li>{{ service.summary || service.description }}</li>
                <li> {{ service.id }}</li>
                <router-link :to="`/service/${service.slug || service.id}`">
                  <el-button type="text">View Service</el-button>
                </router-link>
              </ul>
            </div>
          </div>
        </template>
      </amplify-connect>
    </div>
  </section>
</template>

<script>
import { listServices } from '@/graphql/queries';


export default {
  name: 'market',
  data() {
    return {
      colors: ['orange-pink', 'pink-purple', 'blue-green'],
    }
  },
  computed: {
    listServicesQuery() {
      return this.$Amplify.graphqlOperation(
        listServices,
        {
          limit: 1000,
          filter: {
            archived: { ne: true },
          },
        }
      );
    },
  },
};
</script>
